// src/components/MerchPage/MerchPage.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import Modal from '../Modal/Modal';
import './MerchPage.css';

// Import the images
import essentialsImage from '../../assets/images/essentials.jpg';
import lookingGlassImage from '../../assets/images/looking_glass.jpg';
import Footer from '../Footer/Footer';

interface Collection {
  id: string;
  name: string;
  componentId: string;
  image: string;
}

const collections: Collection[] = [
  {
    id: "295853195426",
    name: "Essentials Collection",
    componentId: "collection-component-1721071530263",
    image: essentialsImage
  },
  {
    id: "304153919650",
    name: "Looking Glass Collection",
    componentId: "collection-component-1721073255574",
    image: lookingGlassImage
  },
];

const MerchPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const layloContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hasSeenModalBefore = localStorage.getItem('hasSeenMerchModal');
    if (!hasSeenModalBefore) {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenMerchModal', 'true');
    }
  }, []);

  useEffect(() => {
    if (isModalOpen && layloContainerRef.current) {
      layloContainerRef.current.innerHTML = '';
      const iframe = document.createElement('iframe');
      iframe.id = 'laylo-drop-88371cb1-805d-4557-a82e-7804cd2fdaf5';
      iframe.allow = 'web-share';
      iframe.style.width = '1px';
      iframe.style.minWidth = '100%';
      iframe.style.maxWidth = '1000px';
      iframe.style.height = '200px';
      iframe.style.border = 'none';
      iframe.src= "https://embed.laylo.com?dropId=88371cb1-805d-4557-a82e-7804cd2fdaf5&color=b6bdce&minimal=false&theme=dark";
      layloContainerRef.current.appendChild(iframe);
    }
  }, [isModalOpen]);

  return (
    <div className="merch-page">
      <Navigation />
      <h1>Merch</h1>
      <div className="content">
        <div className="collection-grid">
          {collections.map((collection) => (
            <Link 
              key={collection.id}
              to={`/merch/${collection.id}`}
              className="collection-card"
              style={{ backgroundImage: `url(${collection.image})` }}
            >
              <div className="collection-overlay">
                <h3>{collection.name}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Sign up for 5% off!</h2>
        <p>Join our text list to receive a 5% off coupon for your next purchase.</p>
        <div ref={layloContainerRef} style={{width: '100%', maxWidth: '1000px', height: '200px'}}></div>
      </Modal>
      <div className="signup-flag" onClick={() => setIsModalOpen(true)}>
        <span>Sign up for 5% Off!</span>
      </div>
    </div>
  );
};


export default MerchPage;
// src/components/ItemPage/ItemPage.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import Modal from '../Modal/Modal';
import './ItemPage.css';
import Footer from '../Footer/Footer';

const ItemPage: React.FC = () => {
  const { collectionId, itemId } = useParams<{ collectionId: string, itemId: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const layloContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hasSeenModalBefore = localStorage.getItem('hasSeenMerchModal');
    if (!hasSeenModalBefore) {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenMerchModal', 'true');
    }
  }, []);

  useEffect(() => {
    if (isModalOpen && layloContainerRef.current) {
      layloContainerRef.current.innerHTML = '';
      const iframe = document.createElement('iframe');
      iframe.id = 'laylo-drop-88371cb1-805d-4557-a82e-7804cd2fdaf5';
      iframe.allow = 'web-share';
      iframe.style.width = '1px';
      iframe.style.minWidth = '100%';
      iframe.style.maxWidth = '1000px';
      iframe.style.height = '200px';
      iframe.style.border = 'none';
      iframe.src= "https://embed.laylo.com?dropId=88371cb1-805d-4557-a82e-7804cd2fdaf5&color=b6bdce&minimal=false&theme=dark";
      layloContainerRef.current.appendChild(iframe);
    }
  }, [isModalOpen]);

  // Here, you would typically fetch the item details using the Shopify API
  // For now, we'll just display the IDs

  return (
    <div className="item-page">
      <Navigation />
      <div className="content">
        <Link to={`/merch/${collectionId}`} className="back-button">← Back to Collection</Link>
        <h1>Item Details</h1>
        <p>Collection ID: {collectionId}</p>
        <p>Item ID: {itemId}</p>
        {/* Display item details here */}
        <Footer />
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Sign up for 5% off!</h2>
        <p>Join our text list to receive a 5% off coupon for your next purchase.</p>
        <div ref={layloContainerRef} style={{width: '100%', maxWidth: '1000px', height: '200px'}}></div>
      </Modal>
      <div className="signup-flag" onClick={() => setIsModalOpen(true)}>
        <span>Sign up for 5% Off!</span>
      </div>
    </div>
  );
};

export default ItemPage;
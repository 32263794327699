// src/components/Footer/Footer.tsx
import React from 'react';
import './Footer.css';

// Import all social media icons
import facebookIcon from '../../assets/images/social-icons/icons8-facebook-240.png';
import instagramIcon from '../../assets/images/social-icons/icons8-instagram-240.png';
import youtubeIcon from '../../assets/images/social-icons/icons8-youtube-240.png';
import tiktokIcon from '../../assets/images/social-icons/icons8-tiktok-240.png';
import appleMusicIcon from '../../assets/images/social-icons/icons8-apple-music-240.png';
import spotifyIcon from '../../assets/images/social-icons/icons8-spotify-240.png';

const Footer: React.FC = () => {
  const socialLinks = [
    { name: 'Facebook', icon: facebookIcon, url: 'https://www.facebook.com/hiimghostsound' },
    { name: 'Instagram', icon: instagramIcon, url: 'https://www.instagram.com/hiimghostsound/' },
    { name: 'TikTok', icon: tiktokIcon, url: 'https://www.tiktok.com/@hiimghostsound?lang=en' },
    { name: 'YouTube', icon: youtubeIcon, url: 'https://www.youtube.com/@HiImGhostSound' },
    { name: 'Spotify', icon: spotifyIcon, url: 'https://open.spotify.com/artist/7IyxUmBgkkV4jh7kHoip34' },
    { name: 'Apple Music', icon: appleMusicIcon, url: 'https://music.apple.com/us/artist/hi-im-ghost/1143628136' }
  ];

  return (
    <footer className="footer">
      <div className="social-icons">
        {socialLinks.map((social) => (
          <a 
            key={social.name} 
            href={social.url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="social-icon-link"
          >
            <img 
              src={social.icon} 
              alt={`${social.name} icon`} 
              className="social-icon"
            />
          </a>
        ))}
      </div>
      <p className="copyright">© {new Date().getFullYear()} Hi Im Ghost. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
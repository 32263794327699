// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import TourPage from './components/TourPage/TourPage';
import MusicPage from './components/MusicPage/MusicPage';
import MerchPage from './components/MerchPage/MerchPage';
import CollectionPage from './components/CollectionPage/CollectionPage';
import ItemPage from './components/ItemPage/ItemPage';
import ChatBotPage from './components/ChatBotPage/ChatBotPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/tour" element={<TourPage />} />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/merch" element={<MerchPage />} />
        <Route path="/merch/:collectionId" element={<CollectionPage />} />
        <Route path="/merch/:collectionId/:itemId" element={<ItemPage />} />
        <Route path="/chat" element={<ChatBotPage/>} />
      </Routes>
    </Router>
  );
};

export default App;
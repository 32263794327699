import React, { useState, useEffect, useRef } from 'react';
import { proxyToOpenAI, OpenAIResponse } from '../../firebase';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import './ChatBotPage.css';

interface Message {
  text: string;
  sender: 'user' | 'bot';
}

const ChatBotPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setMessages([
      { text: "Hi I'm Ghost! What would you like to chat about?", sender: 'bot' }
    ]);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isLoading]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { text: input, sender: 'user' as const };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const result = await proxyToOpenAI({
        model: "gpt-3.5-turbo",
        messages: [
          {"role": "system", "content": "You are Ghosty, a dubstep music producer with heavy metal influences. You're helpful, friendly, and enjoy discussing music production, especially dubstep and metal. Provide music tips and be fun to chat with."},
          ...messages.map(msg => ({
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text
          })),
          {"role": "user", "content": input}
        ]
      });

      console.log('OpenAI API Response:', result);

      if (result.data.choices && result.data.choices.length > 0 && result.data.choices[0].message) {
        const botReply = { text: result.data.choices[0].message.content, sender: 'bot' as const };
        setMessages(prev => [...prev, botReply]);
      } else {
        throw new Error('Unexpected response structure from OpenAI');
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { text: "Oops! Looks like my synths are acting up. Can you try asking me again?", sender: 'bot' as const };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="chatbot-page">
      <Navigation />
      <h1>Chat with Ghosty</h1>
      <div className="chat-container">
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.text}
            </div>
          ))}
          {isLoading && (
            <div className="message bot loading">
              <div className="loading-dots">
                <span>Ghosty is thinking</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSubmit} className="chat-input-form">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message here..."
            className="chat-input"
          />
          <button type="submit" className="chat-submit" disabled={isLoading}>Send</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ChatBotPage;
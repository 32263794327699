// src/components/CollectionPage/CollectionPage.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import ShopifyCollection from "../ShopifyCollection/ShopifyCollection";
import Modal from '../Modal/Modal';
import './CollectionPage.css';
import Footer from '../Footer/Footer';

const collections = {
  "295853195426": {
    name: "Essentials Collection",
    componentId: "collection-component-1721071530263",
  },
  "304153919650": {
    name: "Looking Glass Collection",
    componentId: "collection-component-1721073255574",
  },
};

const CollectionPage: React.FC = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const collection = collections[collectionId as keyof typeof collections];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const layloContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hasSeenModalBefore = localStorage.getItem('hasSeenMerchModal');
    if (!hasSeenModalBefore) {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenMerchModal', 'true');
    }
  }, []);

  useEffect(() => {
    if (isModalOpen && layloContainerRef.current) {
      layloContainerRef.current.innerHTML = '';
      const iframe = document.createElement('iframe');
      iframe.id = 'laylo-drop-88371cb1-805d-4557-a82e-7804cd2fdaf5';
      iframe.allow = 'web-share';
      iframe.style.width = '1px';
      iframe.style.minWidth = '100%';
      iframe.style.maxWidth = '1000px';
      iframe.style.height = '200px';
      iframe.style.border = 'none';
      iframe.src= "https://embed.laylo.com?dropId=88371cb1-805d-4557-a82e-7804cd2fdaf5&color=b6bdce&minimal=false&theme=dark";
      layloContainerRef.current.appendChild(iframe);
    }
  }, [isModalOpen]);

  if (!collection) {
    return <div>Collection not found</div>;
  }

  return (
    <div className="collection-page">
      <Navigation />
      <div className="content">
        <Link to="/merch" className="back-button">← Back to Collections</Link>
        <h1>{collection.name}</h1>
        <ShopifyCollection 
          collectionId={collectionId || ''}
          componentId={collection.componentId}
        />
        <Footer />
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Sign up for 5% off!</h2>
        <p>Join our text list to receive a 5% off coupon for your next purchase.</p>
        <div ref={layloContainerRef} style={{width: '100%', maxWidth: '1000px', height: '200px'}}></div>
      </Modal>
      <div className="signup-flag" onClick={() => setIsModalOpen(true)}>
        <span>Sign up for 5% Off!</span>
      </div>
    </div>
  );
};

export default CollectionPage;
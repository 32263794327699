import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDUj0HN4aFThUwYXLbbHd5AjEGPxW9qDA0",
  authDomain: "hi-im-ghost-site.firebaseapp.com",
  projectId: "hi-im-ghost-site",
  storageBucket: "hi-im-ghost-site.appspot.com",
  messagingSenderId: "1079014054151",
  appId: "1:1079014054151:web:6099cfea9e8f0bcc76cda2"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Functions and get a reference to the service
const functions = getFunctions(app);

// Define the expected response type
export interface OpenAIResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}

// Export the proxyToOpenAI function with type annotation
export const proxyToOpenAI = httpsCallable<any, OpenAIResponse>(functions, 'proxyToOpenAICallable');
// src/components/LandingPage/LandingPage.tsx
import React from 'react';
import MenuItem from '../MenuItem/MenuItem';
import './LandingPage.css';

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <div className="content-wrapper">
        <div className="ghost-image"/>
        <div className="menu">
          <MenuItem to="/tour">Tour</MenuItem>
          <MenuItem to="/music">Music</MenuItem>
          <MenuItem to="/merch">Merch</MenuItem>
          <MenuItem to="/chat">Live Chat</MenuItem>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
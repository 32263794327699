// src/components/TourPage/TourPage.tsx
import React, { useEffect } from 'react';
import Navigation from '../Navigation/Navigation';
import './TourPage.css';
import Footer from '../Footer/Footer';

const TourPage: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//widget-app.songkick.com/injector/9183884';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="tour-page">
      <Navigation />
      <h1>Tour Dates</h1>
      <div className="songkick-widget-container">
        <a 
          href="https://www.songkick.com/artists/9183884" 
          className="songkick-widget" 
          data-theme="dark" 
          data-track-button="on" 
          data-detect-style="off" 
          data-background-color="rgb(0,0,0,1)" 
          data-font-color="rgb(255,255,255,1)" 
          data-button-bg-color="rgb(255,255,255,1)" 
          data-button-text-color="rgb(0,0,0,1)" 
          data-locale="en" 
          data-other-artists="on" 
          data-share-button="on" 
          data-country-filter="on" 
          data-rsvp="on" 
          data-request-show="on" 
          data-past-events="off" 
          data-past-events-offtour="off" 
          data-remind-me="off"
        >
          Tour Dates
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default TourPage;
// src/components/MenuItem/MenuItem.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import './MenuItem.css';

interface MenuItemProps {
  children: React.ReactNode;
  to: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ children, to }) => {
  const [props, set] = useSpring(() => ({ scale: 1, color: 'white' }));

  return (
    <Link to={to} className="menu-item-link">
      <animated.div
        className="menu-item"
        onMouseEnter={() => set({ scale: 1, color: 'red', config: { duration: 200 },} )}
        onMouseLeave={() => set({ scale: 1, color: 'white', config: { duration: 200 }, })}
        style={props}
      >
        {children}
      </animated.div>
    </Link>
  );
};

export default MenuItem;
// src/components/MusicPage/MusicPage.tsx
import React from 'react';
import Navigation from '../Navigation/Navigation';
import './MusicPage.css';
import Footer from '../Footer/Footer';

const MusicPage: React.FC = () => {
  return (
    <div className="music-page">
      <Navigation />
      <h1>Music</h1>
      <div className="spotify-embed-container">
        <iframe 
          style={{borderRadius: '12px'}} 
          src="https://open.spotify.com/embed/artist/7IyxUmBgkkV4jh7kHoip34?utm_source=generator&theme=0" 
          width="100%" 
          height="730" 
          frameBorder="0" 
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy"
          title="Spotify Embed"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
};

export default MusicPage;